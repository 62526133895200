<template>
  <v-app>
    <Snackbar style="z-index: 1000" class="Snack" />
    <v-app-bar v-if="appFrame" app elevation="0" :class="{ 'bg-color-comp': isDark, 'bg-color-comp-light': !isDark }">
      <!-- v-if="$store.state.visibleSearch" -->
      <v-text-field v-if="$store.state.visibleSearch" v-model="search" rounded filled hide-details dense
        :placeholder="`Buscar ${$store.state.searchTerm}`" prepend-inner-icon="fa-search"
        style="border-radius: 10px !important; background-color: transparent">
        <template v-slot:prepend-inner>
          <v-icon small class="mt-1 mr-2">fa-search</v-icon>
        </template>
      </v-text-field>

      <v-spacer></v-spacer>
      <div class="btn-dark mr-4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mr-2" text v-on="on" @click="$router.push({ path: '/sudo/profile' })">
              <v-icon>fa-solid fa-user-gear</v-icon>
            </v-btn>
          </template>
          <span>Configuraciones de usuario</span>
        </v-tooltip>
        <!-- <v-img :src="icon_mode" width="20px" class="mx-2"> </v-img> -->
      </div>
      <div class="btn-dark mr-4">
        <v-switch dense hide-details v-model="$vuetify.theme.dark" @change="modaChange($vuetify.theme.dark)"></v-switch>
        <v-icon width="20px" class="mx-3">
          fa-solid fa-circle-half-stroke
        </v-icon>
        <!-- <v-img :src="icon_mode" width="20px" class="mx-2"> </v-img> -->
      </div>

      <div class="user-descrip">
        <div :class="{ text: true, light_text: !isDark }">
          <v-card-title class="font-weight-bold" style="letter-spacing: 0.070em; line-height: 1.6rem">{{ user_.name
            }}</v-card-title>
          <v-card-subtitle class="font-weight-light" style="margin-top: -22px; letter-spacing: 0.100em">{{
      user_.type | typeUser
    }}</v-card-subtitle>
        </div>
        <img :src="userPhoto || imagedefaul" class="photo" alt="" srcset="" />
      </div>
    </v-app-bar>
    <v-navigation-drawer v-if="navBar" floating app dark permanent class="bg-color-naviagation pb-5">
      <div class="d-flex align-center pa-3">
        <v-img alt="Vuetify Logo" class="shrink mr-2" contain :src="logoCustom" transition="scale-transition"
          height="90px" />
      </div>

      <v-divider></v-divider>
      <div class="app-menu">
        <v-list dense nav v-if="isSudo && business">
          <v-list-item color="primary" v-for="item in menu" :key="item.link" link :to="`${item.link}`" class="pl-4">
            <v-list-item-icon>
              <v-icon color="primary" small>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <transition name="slideX-fade">
            <div>


              <v-list-item color="primary" link to="/events">
                <v-list-item-avatar id="businessMenu" left v-if="business.logo">
                  <v-img :src="business.logo.original || imagedefaul" :lazy-src="business.logo.loading || imagedefaul"
                    max-height="100%" max-width="100%">
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ business.shortName }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


              <v-list-item link color="primary" class="pl-4" to="/userScanner">
                <v-list-item-icon>
                  <v-icon color="primary" small>fa-regular fa-id-badge</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Usuarios escaner</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </transition>

        </v-list>

        <v-list dense nav v-else>
          <v-list-item color="primary" v-for="item in menu" :key="item.link" link :to="`${item.link}`" class="pl-4">
            <v-list-item-icon>
              <v-icon color="primary" small>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>

        <transition name="slide-fade">
          <v-list nav expand dense v-if="selectedEvent_" primary outlined>
            <v-list-group :value="true" class="border-r" style="border-radius: 10px !important" link outline>
              <template v-slot:activator>
                <v-list-item-avatar id="businessMenu" left v-if="selectedEvent_l.cover">
                  <v-img :src="selectedEvent_l.cover.original || imagedefaul"
                    :lazy-src="selectedEvent_l.cover.loading || imagedefaul" max-height="80%" max-width="80%">
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ selectedEvent_l.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon> </v-list-item-icon>
              </template>

              <v-list-item class="ml-6" v-for="(e, index) in typeMenu_list" :key="index"
                style="border-radius: 10px !important" :to="`${e.link}`">
                <v-list-item-icon class="ml-1">
                  <v-icon size="20px" v-text="e.icon" small></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="e.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </transition>
      </div>

      <div class="actions-closed">
        <v-btn @click="logOut" style="padding: 10px !important; margin: 5px; border-radius: 15px" block color="primary"
          large :elevation="0">
          <v-icon small class="mr-2">fa-sign-out-alt</v-icon>

          Cerrar Sesión
        </v-btn>
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view class="pa-6" />
    </v-main>
    <div class="disclamer" v-if="!isPhone">
      <span>
        <i class="fas fa-code-branch"></i>
        v 0.1.0 de
        <i class="fas fa-mug-hot"></i> a <i class="fas fa-code"></i> por:
        <b>
          <a class="white--text" href="http://ocho.life" target="_blank">OCHO APP.
          </a>
        </b>
        &copy; Ocho Corporation S.A. de C.V.
      </span>
    </div>
  </v-app>
</template>

<script>
import Snackbar from "@/components/snackbar";
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
export default {
  name: "App",
  components: {
    Snackbar,
  },
  data: () => ({
    loading: false,
    logoCustom: require("@/assets/dvive.svg"),

    selectedEvent_lMenu: [
      {
        text: "General",
        icon: "fa fa-cogs",
        link: "/general",
      },
      {
        text: "Ubicación",
        icon: "fa-solid fa-map-location-dot",
        link: "/location",
      },
      {
        text: "Galeria",

        icon: "fa-solid fa-images",
        link: "/gallery",
      },
      // {
      //   text: "Mapa",
      //   icon: "fa-solid fa-map",
      //   link: "/map",
      // },
      {
        text: "Secciones",

        icon: "fa-solid fa-icons",
        link: "/sections",
      },
      {
        text: "Horario de funciones",
        icon: "fa-solid fa-calendar-day",
        link: "/FunctionSchedules",
      },

      {
        text: "Entradas",
        icon: "fa-solid fa-ticket-simple",
        link: "/tickets",
      },
      // {
      //   text: "Usuarios scanner",
      //   icon: "fa-regular fa-id-badge",
      //   link: "/userScanner",
      // },
      {
        text: "Reporte Boletos",

        icon: "fa-solid fa-clipboard-check",
        link: "/ticketsSold",
      },
      // {
      //   text: "Carga Masiva",
      //   icon: "fa-solid fa-file-excel",
      //   link: "/BulkLoad",
      // },
      {
        text: "Formulario Adicional",
        icon: "fa-solid fa-sliders",
        link: "/customForm",

      },
    ],

    imagedefaul: require("@/assets/templateImage.svg"),
    userPhoto: require("@/assets/user.svg"),

    drawer: true,
    appFrame: null,
    navBar: null,
    userData: {
      name: null,
      type: null,
    },
    // UsuarioP@ocho.life
    baseRoute: {
      viveBusiness: "/events",
      sudo: "/sudo/business",
      viveSudo: "/sudo/business",
      viveLesserAdmin: "/sudo/business",
      viveFinance: "/sudo/business",
      viveSales: "/sudo/business",
      viveOperations: "/sudo/business",
    },
    //
  }),
  filters: {



    typeUser(e) {
      var roles = {
        viveSudo: 'Super Usuario',
        sudo: 'Super Usuario',
        viveOperations: 'Operaciones',
        viveLesserAdmin: 'Administrador',
        viveBusiness: 'Administrador',
        viveFinance: 'Finanzas',
        viveSales: 'Ventas',

      }
      return roles[e]
    }
  },
  watch: {
    $route(e) {
      this.appFrame = e.meta.appFrame;
      this.navBar = e.meta.navBar;
      this.titlepage(e.meta.titlepage);
      if (e.meta.allowedRoles && !e.meta.allowedRoles.includes(this.role)) {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    console.log(process.env.NODE_ENV);
    setTimeout(() => {
      this.loading = false;
    }, 3000);

    this.$nextTick(() => {
      var getDomain = document.domain;
      var search = getDomain.search("dilo");

      if (search != -1) {
        const favicon = document.getElementById("favicon");
        const title = document.getElementById("title");
        title.innerHTML = "Boleteria Dilo";
        favicon.href =
          "https://static.wixstatic.com/media/eb3096_21be2f65329f44fea5bd3a184c94acb5~mv2.png/v1/fill/w_32%2Ch_32%2Clg_1%2Cusm_0.66_1.00_0.01/eb3096_21be2f65329f44fea5bd3a184c94acb5~mv2.png";

        this.$vuetify.theme.themes.dark.primary = "#ff1544";
        this.$vuetify.theme.themes.light.primary = "#ff1544";
        this.logoCustom = require("@/assets/dilo_logo.webp");
        this.userPhoto =
          "https://firebasestorage.googleapis.com/v0/b/el-ocho-dev-data/o/vive%2Favatar-01.png?alt=media&token=0675c14f-8569-473d-a21c-d502c9bff405";
      } else {
      }

      this.appFrame = this.$route.meta.appFrame;

      this.navBar = this.$route.meta.navBar;
      this.titlepage(this.$route.meta.titlepage);

      fb.auth().onAuthStateChanged((authUser) => {
        if (this.$route.meta) {
          if (authUser) {
            this.$binding(
              "userData",
              db.collection(`appUsers`).doc(authUser.uid)
            )
              .then((user) => {
                if (!user.type || this.$store.state.user.active == false) {
                  //// console.debug("no entra");
                  this.setUserValidity_(false);
                  this.Alert_({
                    text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                    timeout: 2000,
                    btn_closed: true,
                    icon: false,
                    iconType: "mdi-check",
                    type: "error",
                  });
                  this.logOut();
                } else {
                  this.setUser(user);

                  if (user.type == "viveBusiness") {
                    this.getBussines(user.businessId);
                  }
                  if (user.type == "sudo") {
                    this.getBussines(user.businessId);
                  }

                  if (this.$router.currentRoute.name == "login") {
                    setTimeout(() => {
                      this.$router.push({
                        path: this.baseRoute[this.user_.type],
                      });
                    }, 2000);
                  } else if (
                    this.$router.currentRoute.meta.allowedRoles &&
                    !this.$router.currentRoute.meta.allowedRoles.includes(
                      user.type
                    )
                  ) {
                    //validating user permissions for this route
                    this.$router.push({
                      path: this.baseRoute[this.user_.type],
                    });
                  }
              
                }
              })
              .catch((error) => {
                this.Alert_({
                  text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                  timeout: 2000,
                  btn_closed: true,
                  icon: false,
                  iconType: "mdi-check",
                  type: "error",
                });
                this.loading = false;
                this.logOut();
              });
          } else {
            if (this.$router.history.current.fullPath != "/")
              this.$router.push({ path: "/" });
          }
        }
      });

      // this.titlepage(this.$route.meta.titlepage);
    });
  },
  computed: {
    ...mapState([
      "userType",
      "role",
      "user",
      "selectedEvent",
      "search",
      "darkmode",
      "business",
      "selectedCorporative",
    ]),
    menu() {
      if (["sudo", "viveSudo"].includes(this.user.type)) {
        return [
          {
            text: "Comercios",
            icon: "fa-solid fa-store",
            link: "/sudo/business",
          },
          {
            text: "Métodos de pago",
            icon: "fas fa-money-bill-alt",
            link: "/sudo/payment-gateways",
          },
          {
            text: "Ciudades",
            icon: "fa-solid fa-city",
            link: "/sudo/cities",
          },
          {
            text: "Categorias",
            icon: "fas fa-clipboard-list",
            link: "/sudo/categories",
          },
          {
            text: "Etiquetas",
            icon: "fas fa-tags",
            link: "/sudo/tags",
          },
          {
            text: "Usuarios Comerciales",
            icon: "fa-solid fa-user",
            link: "/sudo/users",
            // link: "/special-users",
          },
          {
            text: "Usuarios Especiales",
            icon: "fa-solid fa-users",
            // link: "/sudo/users",
            link: "/special-users",
          },
          {
            text: "Banner App",

            icon: "fa-solid fa-circle-play",
            link: "/sudo/bannerapp",
          },
        ];
      }
      if (this.user.type == "viveBusiness") {
        return [
          {
            text: "Eventos",
            icon: "fa-solid fa-masks-theater",
            link: "/events",
          },
        ]
      }
      if (this.user.type == "viveBusiness") {
        return [
          {
            text: "Eventos",
            icon: "fa-solid fa-masks-theater",
            link: "/events",
          },
        ]
      }
      if (this.user.type == "viveLesserAdmin") {
        return [
          {
            text: "Comercios",
            icon: "fa-solid fa-store",
            link: "/sudo/business",
          },
          {
            text: "Métodos de pago",
            icon: "fas fa-money-bill-alt",
            link: "/sudo/payment-gateways",
          },
          {
            text: "Ciudades",
            icon: "fa-solid fa-city",
            link: "/sudo/cities",
          },
          {
            text: "Categorias",
            icon: "fas fa-clipboard-list",
            link: "/sudo/categories",
          },
          {
            text: "Etiquetas",
            icon: "fas fa-tags",
            link: "/sudo/tags",
          },
          {
            text: "Banner App",

            icon: "fa-solid fa-circle-play",
            link: "/sudo/bannerapp",
          },
        ];
      }
      if( ["viveSales","viveFinance"].includes(this.user.type)){
        return [
          {
            text: "Comercios",
            icon: "fa-solid fa-store",
            link: "/sudo/business",
          },
          {
            text: "Eventos",
            icon: "fa-solid fa-masks-theater",
            link: "/events",
          },
        ]
      }
      if (this.user.type ="viveOperations") {
        return [
          {
            text: "Comercios",
            icon: "fa-solid fa-store",
            link: "/sudo/business",
          },
          {
            text: "Eventos",
            icon: "fa-solid fa-masks-theater",
            link: "/events",
          },
          {
            text: "Métodos de pago",
            icon: "fas fa-money-bill-alt",
            link: "/sudo/payment-gateways",
          },
          {
            text: "Ciudades",
            icon: "fa-solid fa-city",
            link: "/sudo/cities",
          },
          {
            text: "Categorias",
            icon: "fas fa-clipboard-list",
            link: "/sudo/categories",
          },
          {
            text: "Etiquetas",
            icon: "fas fa-tags",
            link: "/sudo/tags",
          },
          {
            text: "Usuarios Comerciales",
            icon: "fa-solid fa-user",
            link: "/sudo/users",
            // link: "/special-users",
          },
        
          {
            text: "Banner App",

            icon: "fa-solid fa-circle-play",
            link: "/sudo/bannerapp",
          },
        ];
        
      }
    },
    isSudo() {
      if (this.user.type == "sudo") {
        return true;
      } else {
        return false;
      }
    },
    isDark() {
      this.$vuetify.theme.dark = this.darkmode;
      return this.$vuetify.theme.dark;
    },
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
    isPhone() {
      const is = screen.width < 500 ? true : false;
      return is;
    },
    user_() {
      return this.user;
    },
    typeMenu_list() {
      if (['sudo','viveSudo','viveLesserAdmin','viveOperations','viveBusiness'].includes(this.user.type)) {
        return this.selectedEvent_lMenu;
      } 
      if (["viveSales","viveFinance"].includes(this.user.type)) {
        return [
           {
        text: "Reporte Boletos",

        icon: "fa-solid fa-clipboard-check",
        link: "/ticketsSold",
      },
      {
        text: "Entradas",
        icon: "fa-solid fa-ticket-simple",
        link: "/tickets",
      },
        ]
        
      }
    },
    selectedEvent_() {
      this.selectedEvent_l = this.selectedEvent;
      return this.selectedEvent_l;
    },
    icon_mode() {
      if (this.$vuetify.theme.dark) {
        return require("@/assets/icon-01.svg");
      } else {
        return require("@/assets/icon-02.svg");
      }
    },
  },
  methods: {
    ...mapActions([
      "titlepage",
      "setUser",
      "Alert_",
      "setUserValidity_",
      "addBusiness",
      "mode",
      "addEventSelection",
    ]),
    async getBussines(id) {
      if (id) {
        await db
          .collection("businesses")
          .doc(id)
          .onSnapshot((response) => {
            let business = response.data();
            // console.debug(business);
            business[`.key`] = response.id;

            // this.selectedEvent_l = business;

            this.addBusiness(business);

            //// console.debug(business);
          });
      }
    },
    modaChange(e) {
      this.mode(e);
    },
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()

          .then(() => {
            this.$router.push({
              path: "/",
            });
            this.selectedEvent_l = null;
            this.addBusiness(null);
            this.addEventSelection(null);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.disclamer {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  font-size: 12px;
  z-index: 10;
  background-color: #160819;
  color: #fff !important;
  padding: 3px 10px;
  text-align: center;
  border-top: #7b7b7b3d solid 1px;
}

.Snack {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateY(10px);
  opacity: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #160819;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: rgba(242, 242, 242, 0.249) !important;
  border-radius: 2px;
}

body {
  background-color: #160819 !important;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slideX-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slideX-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slideX-fade-enter-from,
.slideX-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.app-menu {
  height: 75vh;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 200px;
}
</style>
